<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
                active
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Actas en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Observaciones en Actas PREP
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Observaciones en Actas PREP
          </h2>
          
          <div class="row p-contenedor-30">
            <div class="col-lg-12">
              <p class="f-s-24"> Conoce el tipo de inconsistencias que pueden presentar las Actas PREP, con base en el cual, las Actas pueden ser <strong _ngcontent-qrr-c71="">Contabilizadas</strong> o <strong _ngcontent-qrr-c71="">No Contabilizadas.</strong></p>
            </div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-1.svg" alt=""></div>
            <div class="col-lg-11 col-10">
              <p class="f-s-24 f-w-500"> Contabilizadas </p>
            </div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-12 list-stilo imagenes">
              <p class="f-s-24 text-left"> Las Actas que pertenecen a este grupo cumplen con los siguientes supuestos. </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> Actas de casillas aprobadas. </li>
                <li _ngcontent-qrr-c71=""> Contienen al menos un dato que es posible de sumar. </li>
                <li _ngcontent-qrr-c71=""> El total de votos no excede la Lista Nominal.&nbsp; </li>
              </ul>
              <div class="text-center">
                <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-1.svg" alt="">
                <hr _ngcontent-qrr-c71="">
                <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-2.svg" alt="">
              </div>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <h2 class="f-s-sub text-left"> Se clasifican en Ilegibles y Sin dato </h2>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6">
              <p class="f-s-24 f-w-500 color-r"> Ilegibles </p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-3.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Letra y Número Ilegible </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> La cantidad de votos asentados en uno o más campos en el Acta PREP es ilegible, tanto en letra como en número. Si al menos un dato asentado es legible, se contabiliza </li>
                <li _ngcontent-qrr-c71=""> Los datos ilegibles se contabilizan como cero. </li>
              </ul>
            </div>
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-4.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Número Ilegible </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> La cantidad de votos asentada en número es ilegible.​ </li>
                <li _ngcontent-qrr-c71=""> En este supuesto se captura el dato legible en letra. </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-5.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Letra Ilegible </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> La cantidad de votos asentada en letra es ilegible.​ </li>
                <li _ngcontent-qrr-c71=""> En este caso se captura el dato legible en número. </li>
              </ul>
            </div>
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-6.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Letra y número no coinciden </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> La cantidad de votos asentados en número no coincide con la asentada en letra; por lo tanto prevalecerá la escrita en letra, siempre y cuando no presente alteraciones o tachaduras.​ </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6">
              <p class="f-s-24 f-w-500 color-r"> Sin dato </p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-7.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Cantidad No asentada </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> La cantidad de votos no fue asentada en número, ni en letra. En este caso se captura cada campo sin dato y se contabiliza como cero. </li>
              </ul>
            </div>
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-8.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Cantidad solo en número </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> Cuando la cantidad solo se asentó con número, ésta se toma en cuenta para su captura. </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 imagenes-100 list-stilo sn-m-b">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-9.svg" alt="">
              <p class="f-s-24 f-w-500 m-t-20"> Cantidad solo en letra </p>
              <ul class="f-s-24">
                <li _ngcontent-qrr-c71=""> Cuando la cantidad solo se asentó con letra, ésta se toma en cuenta para su captura. </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-2.svg" alt=""></div>
            <div class="col-lg-11 col-10">
              <p class="f-s-24 f-w-500"> No Contabilizadas </p>
            </div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-12 list-stilo imagenes">
              <p class="f-s-24 text-left"> Las Actas que pertenecen a este grupo, cumplen con los siguientes supuestos:&nbsp; </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> Presentan una omisión, ilegibilidad o error en los campos de identificación del Acta PREP. </li>
                <li _ngcontent-qrr-c71=""> Todos los campos en los que se asientan votos no contienen dato alguno o son ilegibles. </li>
                <li _ngcontent-qrr-c71=""> El total de votos excede la Lista Nominal de la casilla. </li>
              </ul>
              <div class="text-center">
                <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-10.svg" alt="">
                <hr _ngcontent-qrr-c71="">
                <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-11.svg" alt="">
              </div>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 list-stilo sn-m-b imagenes-70">
              <p class="f-s-24 f-w-500 color-r text-left"> Excede la Lista Nominal </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> El total de votos asentado excede el número de ciudadanas y ciudadanos en la Lista Nominal, incluidos los votos de las representaciones de Partidos Políticos y Candidaturas Independientes. </li>
              </ul>
              <div class="text-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-12.svg" alt=""></div>
            </div>
            <div class="col-lg-6 text-center imagenes-70"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-13.svg" alt=""></div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-12">
              <p class="f-s-16"> * Artículo 253, numeral 4, inciso b) de la Ley General de Instituciones y Procedimientos Electorales. </p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-6 list-stilo sn-m-b imagenes-100">
              <div class="text-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-14.svg" alt=""></div>
              <p class="f-s-24 f-w-500 color-r text-left"> Todos los campos ilegibles o vacíos​ </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> Todos los campos de los votos están vacíos o son ilegibles; en estos supuestos, el Acta PREP no se contabiliza y cada campo se captura como “sin dato” o “ilegible” según corresponda.​ </li>
              </ul>
            </div>
            <div class="col-lg-6 list-stilo sn-m-b imagenes-70">
              <div class="text-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-15.svg" alt=""></div>
              <p class="f-s-24 f-w-500 color-r text-center"> No Identificada​ </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> El Acta PREP contiene alguna omisión, ilegibilidad o error en alguno de los campos de identificación, por lo que no es posible ubicarla dentro de la lista de Actas de casillas aprobadas, o bien el Acta carece de algún código, etiqueta o pre impresión que contenga la información de identificación.​ </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-12 list-stilo sn-m-b">
              <p class="f-s-24 f-w-500 color-r text-left"> Sin Acta </p>
              <ul class="f-s-24 text-left">
                <li _ngcontent-qrr-c71=""> En caso de que alguna Acta <strong _ngcontent-qrr-c71="">PREP</strong> no llegue junto con el Paquete Electoral, al Centro de Acopio y Transmisión de datos <strong _ngcontent-qrr-c71="">(CATD)</strong> y no sea posible obtener una copia de ésta del Consejo Distrital correspondiente, no se contabiliza. </li>
              </ul>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-12 list-stilo sn-m-b">
              <p class="f-s-24 f-w-500"><strong _ngcontent-qrr-c71=""> Esto se puede presentar por los siguientes motivos: </strong></p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-4 list-stilo sn-m-b text-center imagenes-70">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-16.svg" alt="">
              <p class="f-s-24 rsp-pb60"><strong _ngcontent-qrr-c71="">No abrió o no se instaló </strong>la Casilla. </p>
            </div>
            <div class="col-lg-4 list-stilo sn-m-b text-center imagenes-70">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-17.svg" alt="">
              <p class="f-s-24 rsp-pb60"> El Paquete Electoral <strong _ngcontent-qrr-c71="">no tiene adherida la Bolsa PREP.</strong></p>
            </div>
            <div class="col-lg-4 list-stilo sn-m-b text-center imagenes-70">
              <img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-18.svg" alt="">
              <p class="f-s-24z"><strong _ngcontent-qrr-c71="">No se entregó el Paquete</strong> Electoral. </p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-12 list-stilo sn-m-b">
              <p class="f-s-24 f-w-500"><strong _ngcontent-qrr-c71=""> Sin Acta de Resultados por contingencia en Urna Electrónica </strong></p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
    <div class="col-lg-6 list-stilo sn-m-b imagenes-30 list-stilo">
      <div class="text-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/observaciones-19.svg" alt=""></div>
      <ul class="f-s-24 text-left">
        <li _ngcontent-qrr-c71=""> Sin Acta de Resultados por contingencia con la urna electrónica debido a la interrupción parcial o total de la operación en la casilla, durante el desarrollo de la Jornada Electoral, situación que provocó el reemplazo de la urna electrónica durante el periodo de votación </li>
      </ul>
    </div>
  </div>
  <div class="row p-contenedor-30 m-b-50">
    <div class="col-lg-12 text-center f-s-16">
      <p class="f-w-500"> Referencias: </p>
      <p class="referencias"> · Numeral 31 del Anexo 13 y Formato 18.5 del Reglamento de Elecciones. Proceso Electoral Federal.​ </p>
      <p class="referencias"> · Modelo de Operación de la casilla única con urna electrónica para los Procesos Electorales Federal y Locales 2023-2024 en las entidades federativas de Coahuila y Jalisco.​ </p>
    </div>
  </div>
  

          
          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Observaciones_en_Actas_PREP.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
